:root {

  --color-white: #FFFFFF;
  --color-black: #000000;

  --color-grey-200: #F5F5F5;
  --color-grey-250: #F3F3F3;
  --color-grey-300: #DDDDDD;
  --color-grey-400: #939393;
  --color-grey-500: #777575;
  --color-grey-600: #5A5A5A;
  --color-grey-700: #333333;

  --color-primary-200: #EC6E6E;
  --color-primary-300: #EFBFBF;
  --color-primary-400: #E08080;
  --color-primary-500: #C00000;
  --color-primary-600: #900000;
  --color-primary-700: #660000;

  --color-semantic-informative: #057AC7;
  --color-semantic-informative-secondary: #ECF9FB;
  --color-semantic-success: #4A8501;
  --color-semantic-success-secondary: #EAF9EA;
  --color-semantic-alert: #BC5000;
  --color-semantic-alert-secondary: #FFF5E1;
  --color-semantic-error: #DD0404;
  --color-semantic-error-secondary: #FBEEED;
  --color-semantic-visited: #7320C2;
  --color-semantic-focus: #2C93F2;

  --color-information-medium: #C8E5F8;
  --color-success-medium: #C4E4C4;
  --color-alert-medium: #FBE4BC;
  --color-error-medium: #F5B4B4;
  --color-new-medium: #DCC0F8;

  --color-focus-text-area: #2C93F2;
  --color-disabled-day-calendar: #CCCCCC;
  --color-hover-calendar: #E0E0E0;

  /* Variables con opacidad */
  --color-black-10: rgba(0, 0, 0, 0.1);
  --color-black-15: rgba(0, 0, 0, 0.15);
  --color-black-60: rgba(0, 0, 0, 0.6);
  --color-black-747: rgba(0, 0, 0, 0.747);
  --color-black-87: rgba(0, 0, 0, 0.87);
  --color-black-90: rgba(0, 0, 0, 0.9);

  --color-grey-700-50: rgba(51, 51, 51, 0.5);
  --color-grey-700-15: rgba(34, 36, 38, 0.15);
}