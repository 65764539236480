@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: 'open-sans-semibold';
  src: url('./OpenSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open-sans-regular';
  src: url('./OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

h4 {
  font-weight: 700;
  line-height: 1.28571429em;
  margin: calc(2rem - .14286em) 0 1rem;
  padding: 0;
}

:root {

  --open-sans-regular: 'open-sans-regular', 'Open Sans', sans-serif;
  --open-sans-semibold: 'open-sans-semibold', 'Open Sans', sans-serif;

  --header-title-font: 600 50px/120% 'Open Sans', sans-serif;
  --header-title-letter-spacing: -0.6px;
  /* DESKTOP */
  /* XL Semibold */
  --desktop-font-xl: 600 40px/120% 'Open Sans', sans-serif;
  --desktop-letter-spacing-xl: -0.6px;

  /* SM Semibold */
  --desktop-font-sm: 600 20px/140% 'Open Sans', sans-serif;
  --desktop-letter-spacing-sm: -0.2px;

  /* BODY */
  /* LG Regular */
  --body-font-lg-regular: 400 20px/150% 'Open Sans', sans-serif;
  --body-letter-spacing-lg-regular: 0px;

  /* MD Regular */
  --body-font-md-regular: 400 16px/150% 'Open Sans', sans-serif;
  --body-letter-spacing-md-regular: 0px;

  /* OTHER */
  --text-error-font: 400 14px/14px 'Open Sans', sans-serif;



  --font-xl-regular: 400 55px/105% 'Open Sans', sans-serif;
  --letter-spacing-xl-regular: -2.2px;

}