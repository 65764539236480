@import "styles/colors.scss";
@import "styles/fonts.scss";

.file-uploader-label {
    display: none;
}

.file-uploader p {
    white-space: pre-line;
}

.file-list {
    display: none;
}

.bi-exclamation-circle::before {
    padding-right: 8px;
    font-size: 20px;
    cursor: pointer;
}